import React, { useEffect, useState } from 'react';

import { useLogin, useTranslate, useNavigation, } from "@pankod/refine-core";

import {
    AntdLayout, Menu, MenuProps, Icons, useMenu
} from "@pankod/refine-antd";
import {
    Title,
    Header,
    Footer,
    OrderBitLayout,
    OffLayoutArea,
} from "components/layout";
import { Routes, Route, Link } from 'react-router-dom';
import { OrderStatusList } from './orderStatus';
import { EmailTemplateList } from './emailTemplates';
import { UserManagementList } from './users';
import { TaxList } from './VAT';
import { ProductStatusList } from './productStatus';
import { MeasurementUnitList } from './measurementUnit';
import { ProfileList } from './profile';
import { PaymentOptionsList } from './PaymentOptions';
import { CustomerGroupList } from './customerGroup';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { useGetIdentity } from "@pankod/refine-core";
import { AdditionChargesList } from './additionalCharges';
import { AuthPermission } from 'utility/authPermission';
import { useMenuContext } from 'contaxt/menuContext';
const { UserOutlined, TeamOutlined, MailOutlined, SettingOutlined,
    CreditCardOutlined, ControlOutlined, PercentageOutlined, ContainerOutlined, ColumnWidthOutlined, PayCircleOutlined } = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;


const items2: MenuProps['items'] = [Icons.UserOutlined, Icons.LaptopOutlined, Icons.NotificationOutlined].map(
    (icon, index) => {
        const key = String(index + 1);

        return {
            key: `sub${key}`,
            icon: React.createElement(icon),
            label: `subnav ${key}`,

            children: new Array(4).fill(null).map((_, j) => {
                const subKey = index * 4 + j + 1;
                return {
                    key: subKey,
                    label: `option${subKey}`,
                };
            }),
        };
    },
);

export const SettingsPage: React.FC = () => {
    const { data: user } = useGetIdentity();
    const { push } = useNavigation();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    
    const {menuPermission} = useMenuContext()

    return (
        <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
            <Sider width={200} className="site-layout-background">
                <h2 style={{ margin: "20px" }}>Settings</h2>
                <Menu
                    theme="light"
                    selectedKeys={[selectedKey]}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    onClick={({ key }) => {
                        push(key as string);
                    }}
                >
                    <Menu.Item
                        icon={<SettingOutlined />}
                        key="/settings/profile"
                        onClick={() => {
                            push("/settings/profile");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/profile") ? "bold" : "normal",
                        }}
                    >
                        Profile
                    </Menu.Item>
                    {
                        user?.defaultRole == "developer" ?
                            <>
                                <Menu.Item
                                    icon={<TeamOutlined />}
                                    key="/settings/users"
                                    onClick={() => {
                                        push("/settings/users");
                                    }}
                                    style={{
                                        fontWeight: (selectedKey === "/settings/users") ? "bold" : "normal",
                                    }}
                                >
                                    Users
                                </Menu.Item>
                                <Menu.Item
                                    icon={<PercentageOutlined />}
                                    key="/settings/tax"
                                    onClick={() => {
                                        push("/settings/tax");
                                    }}
                                    style={{
                                        fontWeight: (selectedKey === "/settings/tax") ? "bold" : "normal",
                                    }}
                                >
                                    Tax
                                </Menu.Item>
                                <Menu.Item
                                    icon={<CreditCardOutlined />}
                                    key="/settings/paymentoptions"
                                    onClick={() => {
                                        push("/settings/paymentoptions");
                                    }}
                                    style={{
                                        fontWeight: (selectedKey === "/settings/paymentoptions") ? "bold" : "normal",
                                    }}
                                >
                                    Payment Options
                                </Menu.Item>
                            </>
                            : ""}
                    {!menuPermission &&
                        <Menu.Item
                            icon={<MailOutlined />}
                            key="/settings/emailtemplates"
                            onClick={() => {
                                push("/settings/emailtemplates");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/settings/emailtemplates") ? "bold" : "normal",
                                pointerEvents: "none"
                            }}
                        >
                            Email Templates
                        </Menu.Item>
                    }
                    {/* <Menu.Item
                        icon={<ContainerOutlined />}
                        key="/settings/orderstatuses"
                        onClick={() => {
                            push("/settings/orderstatuses");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/orderstatuses") ? "bold" : "normal",
                        }}
                    >
                        Order Status
                    </Menu.Item> */}
                    <Menu.Item
                        icon={<ControlOutlined />}
                        key="/settings/productstatuses"
                        onClick={() => {
                            push("/settings/productstatuses");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/productstatuses") ? "bold" : "normal",
                        }}
                    >
                        Product Status
                    </Menu.Item>
                    {!menuPermission &&
                    <Menu.Item
                        icon={<ColumnWidthOutlined />}
                        key="/settings/measurementunits"
                        onClick={() => {
                            push("/settings/measurementunits");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/measurementunits") ? "bold" : "normal",
                        }}
                    >
                        Measuring Units
                    </Menu.Item>
                    }  
                    <Menu.Item
                        icon={<UserOutlined />}
                        key="/settings/users"
                        onClick={() => {
                            push("/settings/users");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/users") ? "bold" : "normal",
                        }}
                    >
                        Users
                    </Menu.Item>
                    {!menuPermission &&
                    <Menu.Item
                        icon={<ContainerOutlined />}
                        key="/settings/tax"
                        onClick={() => {
                            push("/settings/tax");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/tax") ? "bold" : "normal",
                        }}
                    >
                        Tax
                    </Menu.Item>
                    }
                    {!menuPermission &&
                    <Menu.Item
                        icon={<PayCircleOutlined />}
                        key="/settings/paymentoptions"
                        onClick={() => {
                            push("/settings/paymentoptions");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/paymentoptions") ? "bold" : "normal",
                        }}
                    >
                        Payment Options
                    </Menu.Item>
                    }
                    {!menuPermission &&
                    <Menu.Item
                        icon={<CreditCardOutlined />}
                        key="/settings/additionalCharges"
                        onClick={() => {
                            push("/settings/additionalCharges");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/additionalCharges") ? "bold" : "normal",
                        }}
                    >
                        Additional Charges
                    </Menu.Item>
                    }

                    {/* <Menu.Item
                        icon={<UsergroupAddOutlined />}
                        key="/settings/customergroups"
                        onClick={() => {
                            push("/settings/customergroups");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/settings/customergroups") ? "bold" : "normal",
                        }}
                    >
                        Customer Group
                    </Menu.Item> */}
                </Menu>
            </Sider>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Routes>
                    <Route index element={<ProfileList />} />
                    <Route path='orderstatuses' element={<OrderStatusList />} />
                    <Route path='emailtemplates' element={<EmailTemplateList />} />
                    <Route path='users' element={<UserManagementList />} />
                    <Route path='tax' element={<TaxList />} />
                    <Route path='productstatuses' element={<ProductStatusList />} />
                    <Route path='measurementunits' element={<MeasurementUnitList />} />
                    <Route path='profile' element={<ProfileList />} />
                    <Route path='paymentoptions' element={<PaymentOptionsList />} />
                    <Route path='customergroups' element={<CustomerGroupList />} />
                    <Route path='additionalCharges' element={<AdditionChargesList />} />
                    <Route path="*" element={<div>Anything</div>} />
                </Routes>
            </Content>
        </AntdLayout>
    );
};
