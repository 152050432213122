
import {
  CrudFilter,
  CrudFilters,
  HttpError, 
   GetListResponse,

  IResourceComponentsProps,
  useExport,
} from "@pankod/refine-core";
import {
  List,
  Form,
  RefreshButton,
  CreateButton,
  Modal,
  ExportButton,
  Table,
  useTable,
  useSelect,
  Space,
  Button,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Input,
  Grid,
  ModalProps,
  ShowButton,
  FilterDropdown,
  Select,
  AutoComplete,
  SaveButton,
} from "@pankod/refine-antd";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tag, Radio } from "antd";

import { IOrder, IOrderStatus, ICustomer } from "interfaces";
import { CreateOrder } from "./create";
import { EditOrder } from "./edit";
import { GET_ALL_TAXES, GET_CATEGORIES, GET_ORG } from "../../query";
import { useState, useEffect, useRef } from "react";
import { TableColumnsType } from "antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct } from "interfaces";
import { FormProps, Link, NavLink, Route, Routes } from "react-router-dom";
import { useNavigation } from "@pankod/refine-core";

import { symbolName } from "typescript";
import { FieldsOnCorrectTypeRule } from "graphql";
import moment from "moment";
import "./style.css";
import { SearchOutlined } from "@ant-design/icons";
import { QueryObserverResult } from "react-query";

import { Header, Footer, OrderBitLayout } from "components/layout";
import { Update_Order, Update_Status } from "query";
import { log } from "console";
import { OrderDetailShow } from "./Show";
import { AuthPermission } from "utility/authPermission";
import { useMenuContext } from "contaxt/menuContext";

const { PlusOutlined } = Icons;
interface ISearch {
  name: string;
}
// export const OrdersList: React.FC<IResourceComponentsProps> = () => {
//   const [userList, setUserList] = useState([] as any[]);
//   const { data: user } = useGetIdentity();
//   const userId = user?.metadata?.org_id;
//   const { tableProps, sorter, tableQueryResult } = useTable<IOrder>({
//     resource: "orders",
//     initialSorter: [
//       {
//         field: "created_at",
//         order: "desc",
//       },
//     ],
//     permanentFilter: [
//       {
//         field: "ob_org_id",
//         operator: "eq",
//         value: userId,
//       },
//     ],
//     metaData: {
//       fields: ["id", "name", "date", "email", "items", "phone_no", "status", "created_at"],
//     },
//   });

//   let datacount = tableQueryResult?.data?.total;

//   const { formProps, modalProps, show } = useModalForm({
//     resource: "orders",
//     action: "create",
//   });

//   const getUsers = async () => {
//     GET_CATEGORIES().then((response: any) => {
//       setUserList(response.orders);
//     });
//   };
//   useEffect(() => {
//     getUsers();
//   }, []);

//   const {
//     formProps: ViewFormProps,
//     modalProps: ViewModalProps,
//     show: ViewShow,
//   } = useModalForm({
//     resource: "orders",
//     action: "edit",
//     metaData: {
//       fields: ["id", "name", "email", "phone_no", "items", "status", "created_at"],
//     },
//   });

//   const listOrder = ViewFormProps.initialValues;

//   const {
//     formProps: editFormProps,
//     modalProps: editModalProps,
//     show: editShow,
//   } = useModalForm({
//     resource: "orders",
//     action: "edit",
//     metaData: {
//       fields: ["id", "name", "email", "phone_no", "items", "status", "created_at"],
//     },
//   });

//   const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
//     resource: "products",
//     fetchSize: 1000,
//     optionLabel: "name",
//     optionValue: "id",
//     metaData: {
//       fields: ["id", "name"],
//     },
//     sort: [{ field: "name", order: "asc", },]
//   });

//   const { triggerExport, isLoading } = useExport<IOrder>();
//   const [modal2Open, setModal2Open] = useState(false);

//   // const columns: TableColumnsType<IOrder> = [

//   //     {
//   //         dataIndex: "id",
//   //         title: "Id",
//   //     },
//   //     {
//   //         dataIndex: "items",
//   //         title: "Items",
//   //         render: (value) =>
//   //             <div style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any, index: any) => {
//   //                 return <>

//   //                     { (index ? ', ' : '') + dat.name }
//   //                 </>;
//   //             })}</div>,
//   //     },
//   //     {
//   //         dataIndex: "items",
//   //         title: "Total Quantity",
//   //         render: (value) =>
//   //             <div style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
//   //                 return dat.quantity;
//   //             })}</div>,
//   //     },
//   //     {
//   //         dataIndex: "items",
//   //         title: "Total Price",
//   //         render: (value) =>
//   //             <div style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
//   //                 return dat.price;
//   //             })}</div>,
//   //     },
//   //     {
//   //         dataIndex: "createdAt",
//   //         title: "Created_at",
//   //         render: (value) => <DateField format="DD-MMM-YY" value={value} />,
//   //     },
//   //     {
//   //         title: "Updated_at",
//   //         dataIndex: "updatedAt",
//   //         render: (value) => <DateField format="DD-MMM-YY" value={value} />,
//   //     },

//   // ];

//   const orderDate = listOrder?.created_at

//   // const moment = require('moment-timezone')
//   const formateDate = new Date(orderDate)

//   const defaultDate = formateDate.toString()
//   let total = 0;
//   listOrder?.items?.forEach((data: any) => total += data?.price);
//   let initialValue = 0
//   let QuatityinitialValue = 0
//   return (
//     <List
//       pageHeaderProps={{

//         extra: [
//           //  <ExportButton onClick={triggerExport} loading={isLoading} />,
//           <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
//           <CreateButton type="primary" icon={<PlusOutlined />} onClick={() => show()} />,
//         ],

//       }}
//     >
//       {/* {
//                 user?.id === "e613b876-ade3-44a9-b307-274712d190f5" ?
//                     <>
//                         <span>Records: {userList.length}</span>
//                         <Table
//                             size="small"
//                             columns={columns}
//                             dataSource={userList}
//                             pagination={{
//                                 total: userList?.length
//                             }}
//                         />
//                         <EditOrder modalProps={editModalProps} formProps={editFormProps} />
//                     </>
//                     : */}
//       <>

//         <span>Records: {datacount}</span>
//         <Table style={{ marginTop: '5px' }} {...tableProps} rowKey="id" size="small">

//           <Table.Column dataIndex="name" title="Name" />
//           {/* <Table.Column<IProduct> dataIndex="items" title="Items"
//                     render={(_, record) => record?.product?.name}
//                     defaultSortOrder={getDefaultSortOrder("product_id", sorter)}
//                     sorter /> */}
//           {/* <Table.Column dataIndex="date" title="Date" /> */}
//           {/* <Table.Column dataIndex="email" title="Email" /> */}
//           {/* <Table.Column dataIndex="phone_no" title="Phone number" /> */}
//           {/* <Table.Column dataIndex="total_quantity" title="Total Quantity" /> */}
//           {/* <Table.Column dataIndex="items" title="Items"
//                             render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
//                                 let tempData: any = [
//                                     " Name: ",
//                                     dat.name,
//                                     " Quantity: ",
//                                     dat.quantity,
//                                     " Price: ",
//                                     dat.price,
//                                 ]
//                                 return tempData;
//                             })}</span>}
//                         /> */}
//           {/* <Table.Column dataIndex="status" title="Status" /> */}
//           {/* <Table.Column dataIndex="items" title="Images"
//                                 render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
//                                     const valueList = JSON.stringify(dat.image)
//                                     if(dat.image === undefined) {
//                                         return ""
//                                     } else {
//                                         return <img src={valueList} alt="images"></img> + ",";
//                                     }
//                                 })}</span>}
//                             /> */}
//           <Table.Column dataIndex="items" title="Items"
//             render={(value) => <div style={{ width: "250px" }}><span style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
//               return dat.name + ",";
//             })}</span></div>}
//           />
//           <Table.Column dataIndex="items" title="Total Quantity"
//             render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{value.reduce(function (accumulator: any, curValue: any) {

//               return accumulator + curValue.quantity

//             }, QuatityinitialValue)}
//               {/* {value?.map((dat: any) => {
//               return dat.quantity + ",";
//             })} */}
//             </span>}
//           />
//           <Table.Column dataIndex="items" title="Total Price"
//             render={(value) => <span style={{ overflowWrap: 'anywhere' }}>£ {value.reduce(function (accumulator: any, curValue: any) {

//               return accumulator + curValue.price

//             }, initialValue)}
//               {/* {value?.map((dat: any) => {
//               return dat.price + ",";
//             })} */}
//             </span>}
//           />
//           <Table.Column
//             dataIndex="created_at"
//             title="Created At"
//             render={(value) => <DateField value={value} format="LLL" />}
//             defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
//             sorter
//           />
//           <Table.Column<IOrder>
//             title="Actions"
//             dataIndex="actions"
//             render={(_, record) => (
//               <Space>
//                 <Button type="primary"
//                   onClick={() => {
//                     ViewShow(record.id)
//                     setModal2Open(true)
//                   }}
//                   style={{ background: "none", border: "none", boxShadow: "none", padding: "0", color: "#0f6cbe" }}
//                 >
//                   View
//                 </Button>
//                 <EditButton
//                   size="small"
//                   type="link"
//                   icon={null}
//                   recordItemId={record.id}
//                   onClick={() => editShow(record.id)}
//                 />
//                 <DeleteButton
//                   size="small"
//                   type="link"
//                   icon={null}
//                   recordItemId={record.id}
//                 />
//               </Space>
//             )}
//           />
//         </Table >
//       </>
//       <CreateOrder modalProps={modalProps} formProps={formProps} />
//       <EditOrder modalProps={editModalProps} formProps={editFormProps} />
//       <Modal
//         {...modalProps}
//         title="Order Summary"
//         centered
//         visible={modal2Open}
//         footer={false}
//         // onOk={() => setModal2Open(false)}
//         onCancel={() => setModal2Open(false)}
//         width={1200}
//         okText="Edit"

//         bodyStyle={{ margin: "0 -2% -2% -2%" }}

//       >

//         <Container fluid>
//           <Row>
//             <Col>
//               <Container style={{ paddingRight: "0%" }}>
//                 <div style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>
//                   <div  >
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div style={{ marginTop: "20px" }}>
//                         <h4>{listOrder?.name}
//                         </h4>
//                         <p>{moment(defaultDate).format('MMMM Do, YYYY')}</p>
//                       </div>
//                     </div>
//                     {/* <div
//                 style={{ margin: "0 35px 0 0px" }}
//               >
//                 <span style={{ fontWeight: "600", fontSize: "18px" }}>Status: </span>
//                 {
//                   listOrder?.items?.map((data: any) => {
//                     return <span>{data.stock}</span>
//                   })
//                 }
//               </div> */}
//                     {/* <span>. processing</span> */}
//                     <div className="ms-5 d-flex" style={{ margin: "0px 0 30px 0px" }}>
//                       <div>
//                         <h6>Email</h6>
//                         <p>{listOrder?.email}</p>
//                       </div>
//                       {/* <div style={{ borderLeft: "1px solid #ccc", margin: "0 40px 0 100px" }}></div>
//                       <div>
//                         <h6>Phone</h6>
//                         <p>{listOrder?.phone_no}</p>
//                       </div> */}
//                       {/* <div style={{ borderLeft: "1px solid #ccc", margin: "0 15px 0 15px" }}></div> */}
//                       {/* <div>
//                         <h6>Payment</h6>
//                         <p>Manual</p>
//                       </div> */}
//                     </div>
//                   </div>
//                 </div>
//               </Container>
//             </Col>
//             <Col>
//               <Container>
//                 <div
//                 // className="col-md-5"
//                 >
//                   <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none", height: "530px" }}>
//                     {/* <h4 className="mt-3 ms-5">Timeline</h4> */}
//                     {/* <Input type="text" placeholder="Enter customer name" style={{ width: "80%", margin: "0px 0 30px 55px" }} /> */}
//                     <div className="d-flex justify-content-between mt-5 ms-5">
//                       <div>
//                         <span>Shipping</span>
//                       </div>
//                       <div style={{ margin: "0 38px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>£ 0.00</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Tax</span>
//                       </div>
//                       <div style={{ margin: "0 38px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>£ 0.00</span>
//                         {/* <span>USD</span> */}
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Original Total</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <h6>£ {total}.00</h6>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Refunded for Returns</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 0px 0 0" }}>£ 00.00</span>
//                         {/* <span>USD</span> */}
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0px 40px 0px" }}>
//                       <div>
//                         <span>Net Total</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <h6>£ {total}.00</h6>
//                         {/* {
//                           listOrder?.items?.map((data: any) => {
//                             return <h5>£ {data.itemTotal}.00</h5>
//                           })
//                         } */}
//                         {/* <h5>$130.00</h5> */}
//                       </div>
//                     </div>
//                     <div style={{ display: "flex", justifyContent: "end" }}>
//                       <Button style={{ marginInlineEnd: "15px", borderColor: "#4EA144", color: "#4EA144" }} onClick={() => setModal2Open(false)}>Cancel</Button>
//                       <Button type="primary" style={{ marginRight: "44px" }} >Edit</Button>
//                     </div>
//                     {/* <hr style={{ marginTop: "400px" }}></hr> */}
//                     {/* <p className="mt-3 ms-5" style={{ fontWeight: "700" }}>oli@medusajs.com</p>
//                     <span className="ms-5">Sat, 05 Feb 2022 22:16:03 GMT</span> */}
//                     <div>
//                       {/* <div className="d-flex justify-content-between mt-3 ms-5">
//                         <div>
//                           <p>Refund</p>
//                           <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                         </div>
//                         <div>
//                           <p style={{ margin: "0 35px 0 0px" }}>$30.00</p>
//                           <p style={{ margin: "0 35px 0 0px" }}>Return</p>
//                         </div>
//                       </div> */}
//                       {/* <div className="d-flex justify-content-between mt-3 ms-5">
//                         <div>
//                           <p>Refund</p>
//                           <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                         </div>
//                       </div> */}
//                       {/* <div className="d-flex justify-content-between mt-3 ms-5">
//                         <div className="d-flex justify-content-between">
//                           <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
//                           <div>
//                             <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
//                             <p style={{ margin: "0 0 0 10px" }}>s</p>
//                           </div>
//                         </div>
//                         <div style={{ margin: "0 35px 0 0px" }}>
//                           <p>x 1</p>
//                         </div>
//                       </div> */}
//                       {/* <div className="d-flex justify-content-between mt-3 ms-5">
//                         <div>
//                           <p>Refund</p>
//                           <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                         </div>
//                       </div> */}
//                       {/* <div className="d-flex justify-content-between mt-3 ms-5">
//                         <div className="d-flex justify-content-between">
//                           <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
//                           <div>
//                             <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
//                             <p style={{ margin: "0 0 0 10px" }}>s</p>
//                           </div>
//                         </div>
//                         <div style={{ margin: "0 35px 0 0px" }}>
//                           <p>x 1</p>
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-between mt-3 ms-5">
//                         <div>
//                           <p>Refund</p>
//                           <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0 137px 0px" }}>
//                         <div className="d-flex justify-content-between">
//                           <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
//                           <div>
//                             <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
//                             <p style={{ margin: "0 0 0 10px" }}>s</p>
//                           </div>
//                         </div>
//                         <div style={{ margin: "0 35px 0 0px" }}>
//                           <p>x 1</p>
//                         </div>
//                       </div> */}

//                     </div>
//                   </div>
//                 </div>
//               </Container>
//             </Col>
//           </Row>
//           <Col >
//             <Container
//               style={{ position: "absolute", marginTop: "-29%", width: "50%", paddingRight: "2%" }}
//             >
//               <div
//                 // className="card_details"
//                 style={{ margin: "30px,0,0 0 ", boxShadow: "0px 3px 5px 1px #ccc", border: "none", height: "350px", overflowY: "scroll", overflowX: "hidden" }}>
//                 <div>
//                   {/* <h4 className="mt-3 ms-5" style={{ margin: "0px 0 50px 0px" }}>
//                     Summary
//                   </h4> */}
//                   <div >
//                     <div className="justify-content-between mt-3 ms-5">
//                       {/* <Table
//                         dataSource={listOrder}
//                         columns={columnnsss}
//                       /> */}
//                       {/* <Table style={{ marginTop: '5px' }} {...viewstate} rowKey="id" size="small">
//                     <Table.Column dataIndex="items" title="Product"
//                           render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
//                             return dat.name
//                           })}</span>}
//                     />
//                     <Table.Column dataIndex="items" title="Quantity"
//                           render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{ value.reduce(function (accumulator:any, curValue:any) {

//                             return  accumulator + curValue.quantity

//                         }, QuatityinitialValue)}</span>}
//                     />
//                     <Table.Column dataIndex="items" title="Price"
//                           render={(value) => <span style={{ overflowWrap: 'anywhere' }}>£ { value.reduce(function (accumulator:any, curValue:any) {

//                             return accumulator + curValue.price

//                         }, initialValue)}</span>}
//                     />
//                     </Table> */}
//                       <Row style={{ paddingTop: "25px" }}>
//                         <Col>
//                           <h6>Products</h6>
//                         </Col>
//                         <Col>
//                           <h6 style={{ marginLeft: "210px" }}>Quantity</h6>
//                         </Col>
//                         <Col>
//                           <h6 style={{ marginLeft: "40px" }}>Price</h6>
//                         </Col>
//                       </Row>
//                       {
//                         listOrder?.items?.map((data: any) => {
//                           return <>
//                             <div style={{ display: "flex", marginTop: "15px" }}>
//                               {/* <img src={data.image} alt="product image" width="35px" height="55px" style={{ borderRadius: "12px" }}></img> */}
//                               <div style={{ width: "355px" }}><span>{data.name}</span></div>
//                               <div style={{ width: "103px" }}><span>{data.quantity}</span></div>
//                               <div><span>£ {data.price}</span></div>
//                               {/* <span style={{ margin: "0 10px 0 0" }}>£ {data.itemTotal}</span> */}
//                             </div>
//                           </>
//                         })}
//                     </div>
//                     {/* <div className="d-flex justify-content-between mt-3 ms-5">
//              <div>
//                <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height= "55px" style={{borderRadius:"12px"}}/>
//                <span style={{margin:"0 0 0 10px"}}>Medusa Water Bottle</span>
//              </div>
//              <div style={{margin:"0 45px 0 0px"}}>
//                <span style={{margin:"0 10px 0 0"}}>$30.00</span>
//                <span style={{margin:"0 10px 0 0"}}>x 3</span>
//                <span style={{margin:"0 10px 0 0"}}>$90.00</span>
//                <span>usd</span>
//              </div>
//           </div>
//           <div className="d-flex justify-content-between mt-3 ms-5">
//              <div>
//                <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height= "55px" style={{borderRadius:"12px"}}/>
//                <span style={{margin:"0 0 0 10px"}}>Medusa Water Bottle</span>
//              </div>
//              <div style={{margin:"0 45px 0 0px"}}>
//                <span style={{margin:"0 10px 0 0"}}>$30.00</span>
//                <span style={{margin:"0 10px 0 0"}}>x 3</span>
//                <span style={{margin:"0 10px 0 0"}}>$90.00</span>
//                <span>usd</span>
//              </div>
//           </div> */}
//                     {/* <div
//                       className="d-flex justify-content-between mt-3 ms-5"
//                     >
//                       <div>
//                         <span>Subtotal</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <span style={{ margin: "0 10px 0 0" }}>£ {data.itemTotal}.00</span>
//                           })
//                         }
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Shipping</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>£ 0.00</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Tax</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>£ 0.00</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Original Total</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <h3>£ {total}.00</h3>

//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Refunded for Returns</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>£ 30.00</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0px 40px 0px" }}>
//                       <div>
//                         <span>Net Total</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <h3>£ {total}.00</h3>

//                       </div>
//                     </div> */}
//                   </div>
//                 </div>
//               </div>
//             </Container>
//           </Col>
//         </Container>
//       </Modal>

//     </List >
//   );
// };

export const OrdersList: React.FC<IResourceComponentsProps> = () => {
  const [userList, setUserList] = useState([] as any[]);
  const { data: user } = useGetIdentity();
  const route = useNavigation()
  const userId = user?.metadata?.org_id;
  const { push } = useNavigation();
  const [ordersFilters, setOrdersFilters] = useState<any>([
    {
      field: "ob_org_id",
      operator: "eq",
      value: userId,
    },
  ]);
  const [customerId, setCustomerId] = useState<any>();
  const [myStatus, setMyStatus] = useState<any>([]);
  const [statusChange, setStatusChange] = useState<any>(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [showModalVisible, setShowModalVisible] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [allTaxes, setAllTaxes] = useState<any>(null);
  // const [menuPermission, setPermissions] = useState<any>(true)
  const {menuPermission} = useMenuContext()
    
  useEffect(() => {
    setOrdersFilters([
      {
        field: "customer_id",
        operator: "eq",
        value: customerId,
      },
      // {
      //   field: "ob_org_id",
      //   operator: "eq",
      //   value: userId,
      // },
      {
        field: "status",
        operator: "eq",
        value: status,
      },
    ]);
  }, [customerId]);

  const handleSearch = (e: any) => {
    setCustomerId(e);
  };

  const handleOnClear = () => {
    setStatus(null);
  };
  const handleOnClearSearch = () => {
    setCustomerId(null);
  };
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState(null);
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IOrder>({
    resource: "orders",
    initialPageSize: 10,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId, 
      },
      {
        field: "customer_id",
        operator: "eq",
        value: customerId,
      },
      {
        field: "status",
        operator: "contains",
        value: status,
      },
      // ...ordersFilters,
    ],
    // permanentFilter: ordersFilters,
    metaData: {
      fields: [
        "id",
        "name",
        "date",
        "email",
        "items",
        "phone_no",
        "status",
        "order_Unique_id",
        "created_at",
        "total_price",
        "address",
        "delivery_Instruction",
        "despatch_status",
        "order_status",
        "order_Type",
        "sage_order_ref_no",
      ],
    },
  });

  const { tableProps:newTableProps, sorter: newSorter, tableQueryResult: newTableQueryResult, searchFormProps: newSearchFormProps } = useTable<IOrder>({
    resource: "orders",
    initialPageSize: 10,
    initialCurrent: 1,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId, 
      },
      {
        field: "customer_id",
        operator: "eq",
        value: customerId,
      },
      {
        field: "status",
        operator: "contains",
        value: status,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "date",
        "email",
        "items",
        "phone_no",
        "status",
        "order_Unique_id",
        "created_at",
        "total_price",
        "address",
        "trip_id",
        "despatch_status",
        "order_status",
        "order_Type",
        "sage_order_ref_no",
      ],
    },
  });

  const tableData = useTable<IOrderStatus>({
    resource: "order_statuses",

    queryOptions: {
      staleTime: 0,
      cacheTime: 0, 
    },

    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "sort",
        "bg_color",
        "ob_org_id",
        "notify_customer",
      ],
    },
  });

  const { selectProps: OrderStasusSelectProps } = useSelect<IProduct>({
    resource: "order_statuses",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    
    sort: [{ field: "name", order: "asc", },]
  });

  const getAllTaxes = () => {
    GET_ALL_TAXES(userId).then((res: any) => {
      setAllTaxes(res?.taxes)
    })
  }

  useEffect(() => {
    const refet = async () => {
      const result = await tableQueryResult.refetch();
    };

    refet();
  }, []);

  useEffect(() => {    
      !myStatus.length &&
        setMyStatus(() => {
          return Array.from(
            new Set(tableProps.dataSource?.map((item) => item.status))
          );
        }); 
        getAllTaxes();
  }, [tableProps.dataSource, statusChange == true]);
  const { selectProps } = useSelect<any>({
    resource: "customers",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters:[
      {
        field:"ob_org_id",
        operator:"eq",
        value:userId
      }
     ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  useEffect(() => {
    const copyFilters = [...ordersFilters];
    copyFilters.pop();
    copyFilters.push({
      field: "status",
      operator: "eq",
      value: status,
    });

    setOrdersFilters([...copyFilters]);
  }, [status]);

  //  useEffect(()=>{
  //   const searchFilter = [...ordersFilters]
  //   console.log("search Filter",searchFilter);
  //   searchFilter.pop();
  //   searchFilter.push({
  //     field:"name",
  //     operator:"contains",
  //     value:search
  //   });

  //  },[search])

  useEffect(() => {
    setTimeout(() => {
      setMyStatus(() => {
        return Array.from(
          new Set(tableProps.dataSource?.map((item) => item.status))
        );
      });  
    }, 500);
    getAllTaxes();
  }, [tableProps.dataSource, statusChange == true, !showModalVisible]);
  
    // const { selectProps } = useSelect<any>({
  //   resource: "customers",
  //   metaData: {
  //     fields: ["id", "name"],
  //   },
  //   optionLabel: "name",
  //   optionValue: "id",

  //   onSearch: (value) => [
  //     {
  //       field: "name",
  //       operator: "contains",
  //       value: "%" + value + "%",
  //     },
  //   ],
  // });

  useEffect(() => {
    const copyFilters = [...ordersFilters];
    copyFilters.pop();
    copyFilters.push({
      field: "status",
      operator: "eq",
      value: status,
    });

    setOrdersFilters([...copyFilters]);
  }, [status]);

  //  useEffect(()=>{
  //   const searchFilter = [...ordersFilters]
  //   console.log("search Filter",searchFilter);
  //   searchFilter.pop();
  //   searchFilter.push({
  //     field:"name",
  //     operator:"contains",
  //     value:search
  //   });

  //  },[search])

  let datacount = tableQueryResult?.data?.total;
  const orderStatusList = tableData.tableProps?.dataSource?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const getBadgeColor = (type: any, dataSource: any) => {
    const item = dataSource?.find(
      (item: any) => item.name.toLowerCase() === type.toLowerCase()
    ) ?? { bg_color: "#FFFFFF" };
    return item?.bg_color ?? "#FFFFFF";
  };

  const BadgeItem = (status: string, bg_color?: string) => {
    return <Tag color={getBadgeColor(status, bg_color)}>{status}</Tag>;
  };
  const handleChange = (id: string, value: string) => {    
    setStatusChange(false);
    Update_Status(id, value);
    setTimeout(() => {
      tableQueryResult?.refetch();
      setStatusChange(true);
    }, 300);
  };

  const DropDown = (statuss: string, record: any) => {    
    return (
      <Select 
      className="status-select"
       bordered={false}  
        defaultValue={statuss}
        dropdownStyle={{border:"none",paddingRight:"1px "}}
        style={{
          width: 200,
          border: "none",
          borderColor: `1px solid ${getBadgeColor(
            statuss,
            tableData.tableProps?.dataSource
          )}`,
          // background: getBadgeColor(statuss, tableData.tableProps?.dataSource),
        }}
        onSelect={(status: string) => handleChange(record.id, status)}
        onChange={(status) => {
          handleChange(record.id, status);
        }}
        dropdownRender={(status) => <>{status}</>}
        options={orderStatusList?.map((e) => ({
          label: drowpItem(e.label),
          value: e.value,
        }))}
      />
    );
  };

  const drowpItem = (status: string) => {
    return (
      <Tag
        color={getBadgeColor(status, tableData.tableProps?.dataSource)}
        style={{ width: "100%" }}
      >
        {status}
      </Tag>
    );
  };
  const { formProps, modalProps, show } = useModalForm({
    resource: "orders",
    action: "create",
  });

  const {
    formProps: showFormProps,
    modalProps: showModalProps,
    show: ShowPopup,

} = useModalForm({
    resource: "orders",
    action: 'clone',
    redirect: false,
    metaData: {
        fields: ["id", "name", "email", "phone_no", "items", "status", "created_at", "order_Unique_id", "date", "phone_no", "total_price", "address", "trip_id","despatch_status","order_status", "order_Type", "sage_order_ref_no","delivery_Instruction","customer_id"],
    },
});
  const getUsers = async () => {
    GET_CATEGORIES().then((response: any) => {
      setUserList(response.orders);
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const {
    formProps: ViewFormProps,
    modalProps: ViewModalProps,
    show: ViewShow,
  } = useModalForm({
    resource: "orders",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "name",
        "email",
        "phone_no",
        "items",
        "status",
        "created_at",
      ],
    },
  });

  const listOrder = ViewFormProps.initialValues;

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "orders",
    action: "edit",
    metaData: {
      fields: [
        "id",
        "name",
        "email",
        "phone_no",
        "items",
        "status",
        "created_at",
      ],
    },
  });
  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc" }],
  });

  const { triggerExport, isLoading } = useExport<IOrder>();
  const [modal2Open, setModal2Open] = useState(false);

  const columns: TableColumnsType<IOrder> = [
    {
      dataIndex: "order_Unique_id",
      title: "Id",
    },
    {
      dataIndex: "items",
      title: "Items",
      render: (value) => (
        <div style={{ overflowWrap: "anywhere" }}>
          {value?.map((dat: any, index: any) => {
            return <>{(index ? ", " : "") + dat.name}</>;
          })}
        </div>
      ),
    },
    {
      dataIndex: "items",
      title: "Total Quantity",
      render: (value) => (
        <div style={{ overflowWrap: "anywhere" }}>
          {value?.map((dat: any) => {
            return dat.quantity;
          })}
        </div>
      ),
    },
    {
      dataIndex: "items",
      title: "Total Price",
      render: (value) => (
        <div style={{ overflowWrap: "anywhere" }}>
          {value?.map((dat: any) => {
            return dat.price;
          })}
        </div>
      ),
    },
    {
      dataIndex: "createdAt",
      title: "Created_at",
      render: (value) => <DateField format="DD-MMM-YY" value={value} />,
    },
    {
      title: "Updated_at",
      dataIndex: "updatedAt",
      render: (value) => <DateField format="DD-MMM-YY" value={value} />,
    },
  ];

  const orderDate = listOrder?.created_at;

  // const moment = require('moment-timezone')
  const formateDate = new Date(orderDate);

  const defaultDate = formateDate.toString();
  let total = 0;
  listOrder?.items?.forEach((data: any) => (total += data?.price));
  let initialValue = 0;
  let QuatityinitialValue = 0;

  // const tablePropss = tableProps
  // const date = tablePropss.dataSource?.map((item: any) => item?.date)
  // const dateformat = moment(date).format("MMMM Do YYYY")
  // localStorage.setItem("order", JSON.stringify(tableProps))
  const handleCloseCreateModal = () => setCreateModalVisible(false);
  const handleCloseShowModal = () => setShowModalVisible(false);

  // onSearch: (params: { status: any }) => {
  //   const filters: CrudFilters = [];
  //   const { status } = params;

  //   filters.push({
  //     field: "Status",
  //     operator: "eq",
  //     value: status,
  //   });
  //   return filters;
  // };

  const handleSelect = (e: any) => {

    setStatus(e);
  };
  const handleCheck = (e: any) => {
    setSearch(e);
  };;

  
  useEffect(() => {
    setTimeout(() => {
      tableQueryResult?.refetch();
    }, 500);
  }, [createModalVisible, showModalVisible]);
  
  useEffect(() => {
    setShowTable(false);
    if(tableProps?.dataSource){
      setTimeout(() => {
        // const setStatusTag = tableProps?.dataSource?.map((data:any) => {
        //   return handleChange(data?.id, data?.status)
        //     // return DropDown(data?.status, data)
        // }) 
        setShowTable(true);
      }, 300);
   }
  }, [tableProps?.dataSource]);

  return (
    // <OrderBitLayout Header={Header} Footer={Footer} dashboard={true}>
    <List
      pageHeaderProps={{
        extra: [
          <div className="order-list-filter-container">
            <Form
              layout="vertical"
              className="order-list-filter-form"
            >
              <Form.Item label="Customer">
                <Select
                  placeholder="Select a customer"
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
              {!menuPermission &&
                <Form.Item label="Status" name="Status">
                  <Select
                    allowClear
                    options={tableData?.tableProps?.dataSource?.map(
                      (item: any) => ({
                        value: item?.name,
                        label: item?.name,
                      })
                    )}
                    placeholder="Check Status"
                    onSelect={handleSelect}
                    onClear={handleOnClear}
                  />
                </Form.Item>
              }
            </Form>
            <CreateButton
              type="primary"
              children={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PlusOutlined style={{ marginRight: "5px" }} />
                  Create
                </div>
              }
              icon={false}
              resourceName="orders"
              onClick={() =>
                // show()
                setCreateModalVisible(true)
              }
            />
          </div>
        ]
      }}
    >
      {/* {
                user?.id === "e613b876-ade3-44a9-b307-274712d190f5" ?
                    <>
                        <span>Records: {userList.length}</span>
                        <Table
                            size="small"
                            columns={columns}
                            dataSource={userList}
                            pagination={{
                                total: userList?.length
                            }}
                        />
                        <EditOrder modalProps={editModalProps} formProps={editFormProps} />
                    </>
                    : */}
      <>
        {/* <span>Records: {datacount}</span> */}
        {showTable ? (
          <>
            {customerId || status ? (
              <Table
                className="listTable"
                style={{
                  marginTop: "5px",
                  justifyContent: "center",
                  gridRow: "auto",
                  cursor: "pointer",
                }}
                {...newTableProps}
                rowKey="id"
                size="small"
                // pagination={false}
              >
                <Table.Column
                  dataIndex="order_Unique_id"
                  title="Order Id"
                  align="center"
                  render={(value, record: any) => (
                    <span
                      onClick={() => {
                        ShowPopup(record.id);
                        setShowModalVisible(true);
                      }}
                    >
                      {value}
                    </span>
                  )}
                />

                <Table.Column
                  dataIndex="date"
                  title="Date"
                  align="center"
                  render={(value) => <DateField value={value} format="LL" />}
                  defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                  sorter
                />
                <Table.Column
                  dataIndex="name"
                  title="Customer Name"
                  align="center"
                />
                <Table.Column
                  dataIndex="order_Type"
                  title="Type"
                  align="center"
                />
                <Table.Column
                  dataIndex="items"
                  title="Quantity"
                  align="center"
                  render={(value) => (
                    <span style={{ overflowWrap: "anywhere" }}>
                      {value.reduce(function (accumulator: any, curValue: any) {
                        return accumulator + Number(curValue.quantity);
                      }, QuatityinitialValue)}
                    </span>
                  )}
                />
                {/* <Table.Column
                  dataIndex="items"
                  title="Price"
                  align="center"
                  render={(value) => (
                    <span style={{ overflowWrap: "anywhere" }}>
                      £{" "}
                      {value
                        .reduce(function (accumulator: any, curValue: any) {
                          return (
                            accumulator + curValue.price * curValue.quantity
                          );
                        }, initialValue)
                        .toFixed(2)}
                    </span>
                  )}
                /> */}
                 {/* <Table.Column
                  dataIndex="items"
                  title="Price"
                  align="center"
                  render={(val) => {
                    const totalPrice = val?.reduce(
                      (accumulator: any, curValue: any) =>
                        accumulator + curValue.price * curValue.quantity,
                      0
                    );

                    const totalTax = val?.reduce(
                      (accumulator: any, curValue: any) => {
                        if (curValue.tax_id !== null) {
                          const tax = allTaxes?.find(
                            (tax: any) => tax.id === curValue.tax_id
                          );
                          if (tax) {
                            return (
                              accumulator +
                              curValue.quantity *
                                curValue.price *
                                (tax.vat_rate / 100)
                            );
                          }
                        }
                        return accumulator;
                      },
                      0
                    );

                    const grandTotal = totalPrice + totalTax;

                    return (
                      <span style={{ overflowWrap: "anywhere" }}>
                        £ {grandTotal.toFixed(2)}
                      </span>
                    );
                  }}
                /> */}
                <Table.Column
                  dataIndex="total_price"
                  title="Price"
                  align="center"
                  render={(value:any) => {
                    return <span>£ {value?.toFixed(2)}</span>
                  }}
                  />
                {/* <Table.Column
                  dataIndex="status"
                  title="Status"
                  align="center"
                  render={(value, record) => {
                    return DropDown(value, record);
                  }}
                /> */}
                {/* <Table.Column
                  dataIndex="order_status"
                  title="Order Status"
                  render={(value) => {
                    const findStatus = OrderStasusSelectProps?.options?.find((res:any) => value == res?.value)            
                    return findStatus?.label
                  }}
                /> */}
                {!menuPermission &&
                  <Table.Column
                    dataIndex="despatch_status"
                    title="Despatch Status"
                    align="center"
                  />
                }
                <Table.Column<IOrder>
                  title="Actions"
                  dataIndex="actions"
                  align="center"
                  render={(_, record) => (
                    <Space>
                      <ShowButton
                        hideText={true}
                        style={{ border: "none" }}
                        resourceName={"orders"}
                        onClick={() => {
                          ShowPopup(record.id);
                          setShowModalVisible(true);
                        }}
                      ></ShowButton>
                    </Space>
                  )}
                />
              </Table>
            ) : (
              <Table
                className="listTable"
                style={{
                  marginTop: "5px",
                  justifyContent: "center",
                  // border: "2px solid lightgray",
                  gridRow: "auto",
                  cursor: "pointer",
                }}
                {...tableProps}
                rowKey="id"
                size="small"
                // onRow={(record) => ({
                //   onClick: () => {
                //     push(`./show/${record?.id}`);
                //     console.log("record is ", record);
                //   },
                // })}
              >
                {/* <div style={{ border: "10px", borderRadius: "10px", backgroundColor: "red" }}> */}
                <Table.Column
                  dataIndex="order_Unique_id"
                  title="Order Id"
                  align="center"
                  render={(value, record: any) => (
                    <span
                      // onClick={() =>
                      // push(`./show/${(record as { id: string }).id}`)
                      // }
                      onClick={() => {
                        ShowPopup(record.id);
                        setShowModalVisible(true);
                      }}
                    >
                      {value}
                      {/* <p>{value}</p> */}
                    </span>
                  )}
                />

                <Table.Column
                  dataIndex="date"
                  title="Date"
                  align="center"
                  render={(value) => <DateField value={value} format="LL" />}
                  defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                  sorter
                />
                <Table.Column
                  dataIndex="name"
                  title="Customer Name"
                  align="center"
                />
                <Table.Column
                  dataIndex="order_Type"
                  title="Type"
                  align="center"
                />
                {/* <Table.Column dataIndex="items" title="Items"
            render={(value) => <div style={{ width: "250px" }}><span style={{ overflowWrap: 'anywhere' }}>{value?.map((dat: any) => {
              return dat.name + ",";
            })}</span></div>}
          /> */}
            {menuPermission &&
                <Table.Column
                  dataIndex="items"
                  title="No. of Product"
                  align="center"
                  render={(value:any) => {
                    
                    return <span>{value?.length}</span>
                  }}
                />
              }
                <Table.Column
                  dataIndex="items"
                  title="Quantity"
                  align="center"
                  render={(value) => (
                    <span style={{ overflowWrap: "anywhere" }}>
                      {value.reduce(function (accumulator: any, curValue: any) {
                        return accumulator + Number(curValue.quantity);
                      }, QuatityinitialValue)}
                      {/* {value?.map((dat: any) => {
              return dat.quantity + ",";
            })} */}
                    </span>
                  )}
                />
                {/* <Table.Column
                  dataIndex="items"
                  title="Price"
                  align="center"
                  render={(val) => {
                    const totalPrice = val?.reduce(
                      (accumulator: any, curValue: any) =>
                        accumulator + curValue.price * curValue.quantity,
                      0
                    );

                    const totalTax = val?.reduce(
                      (accumulator: any, curValue: any) => {
                        if (curValue.tax_id !== null) {
                          const tax = allTaxes?.find(
                            (tax: any) => tax.id === curValue.tax_id
                          );
                          if (tax) {
                            return (
                              accumulator +
                              curValue.quantity *
                                curValue.price *
                                (tax.vat_rate / 100)
                            );
                          }
                        }
                        return accumulator;
                      },
                      0
                    );

                    const grandTotal = totalPrice + totalTax;

                    return (
                      <span style={{ overflowWrap: "anywhere" }}>
                        £ {grandTotal.toFixed(2)}
                      </span>
                    );
                  }}
                /> */}

                <Table.Column
                  dataIndex="total_price"
                  title="Price"
                  align="center"
                  render={(value:any) => {
                    return <span>£ {value?.toFixed(2)}</span>
                  }}
                  />
                {/* <Table.Column
                  dataIndex="status"
                  title="Status"
                  align="center"
                  render={(value, record) => {
                    return DropDown(value, record);
                  }}
                /> */}
                {/* <Table.Column
                  dataIndex="order_status"
                  title="Order Status"
                  render={(value) => {
                    const findStatus = OrderStasusSelectProps?.options?.find((res:any) => value == res?.value)            
                    return findStatus?.label
                  }}
                /> */}
                {!menuPermission &&
                  <Table.Column
                    dataIndex="despatch_status"
                    title="Despatch Status"
                    align="center"
                  />
                }
                <Table.Column<IOrder>
                  title="Actions"
                  dataIndex="actions"
                  align="center"
                  render={(_, record) => (
                    <Space>
                      <ShowButton
                        hideText={true}
                        style={{ border: "none" }}
                        resourceName={"orders"}
                        // icon
                        // recordItemId={record?.id}
                        // onClick={() => route?.push(`./show/${record.id}`)}
                        onClick={() => {
                          ShowPopup(record.id);
                          setShowModalVisible(true);
                        }}
                      ></ShowButton>
                    </Space>
                  )}
                />
                {/* </div> */}
              </Table>
            )}
          </>
        ) : (
          <></>
        )}
      </>
      <CreateOrder
        tableQueryResult={tableQueryResult}
        closeCreateModal={handleCloseCreateModal}
        createModalVisible={createModalVisible}
        modalProps={modalProps}
        formProps={formProps}
      />
      <EditOrder modalProps={editModalProps} formProps={editFormProps} />
      <OrderDetailShow
        tableShowQueryResult={tableQueryResult}
        closeCreateModal={handleCloseShowModal}
        createModalVisible={showModalVisible}
        modalProps={showModalProps}
        formProps={showFormProps}
        updatedValue={tableProps}
      />

      {/* <OrderDetail listOrder={listOrder}  /> */}

      {/* <Modal
        {...modalProps}
        title="Order Summary"
        centered
        visible={modal2Open}
        footer={false}
        // onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        width={1200}
        okText="Edit"
        bodyStyle={{ margin: "0 -2% -2% -2%" }}
      >
       
      </Modal> */}
    </List>
    // </OrderBitLayout>
  );
};

// import React from 'react';

// const list = () => {
//   return (
//     <div>
//        <>
//           <div
//           className="row"
//           >
//             <div

//               className="col-md-7"
//             >

//               {/* <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}> */}
//               <div
//                 className="card"
//                 style={{ marginBottom: "2%", boxShadow: "0px 3px 5px 1px #ccc", border: "none", height: "15%" }}
//               >
//                 <div className="d-flex justify-content-between mt-3 ms-5" >
//                   <div>
//                     <h4>#1</h4>
//                     <p>10 Oct 2021 04:31</p>
//                   </div>
//                   <div
//                     style={{ margin: "0 35px 0 0px" }}
//                   >
//                     <span style={{ fontWeight: "600", fontSize: "18px" }}>Status: </span>
//                     {
//                       listOrder?.items?.map((data: any) => {
//                         return <span>{data.stock}</span>
//                       })
//                     }
//                     {/* <span>. processing</span> */}
//                   </div>
//                   <div className="ms-5 d-flex" style={{ margin: "0px 0 30px 0px" }}>
//                     <div>
//                       <h6>Email</h6>
//                       <p>{listOrder?.email}</p>
//                     </div>
//                     <div style={{ borderLeft: "1px solid #ccc", margin: "0 15px 0 15px" }}></div>
//                     <div>
//                       <h6>Phone</h6>
//                       <p>123456789</p>
//                     </div>
//                     <div style={{ borderLeft: "1px solid #ccc", margin: "0 15px 0 15px" }}></div>
//                     <div>
//                       <h6>Payment</h6>
//                       <p>Manual</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div
//               // className="col-md-5"
//               >
//                 <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>
//                   <h4 className="mt-3 ms-5">Timeline</h4>
//                   <Input type="text" placeholder="Enter customer name" style={{ width: "80%", margin: "0px 0 30px 55px" }} />
//                   <hr></hr>
//                   <p className="mt-3 ms-5" style={{ fontWeight: "700" }}>oli@medusajs.com</p>
//                   <span className="ms-5">Sat, 05 Feb 2022 22:16:03 GMT</span>
//                   <div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <p>Refund</p>
//                         <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                       </div>
//                       <div>
//                         <p style={{ margin: "0 35px 0 0px" }}>$30.00</p>
//                         <p style={{ margin: "0 35px 0 0px" }}>Return</p>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <p>Refund</p>
//                         <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div className="d-flex justify-content-between">
//                         <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
//                         <div>
//                           <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
//                           <p style={{ margin: "0 0 0 10px" }}>s</p>
//                         </div>
//                       </div>
//                       <div style={{ margin: "0 35px 0 0px" }}>
//                         <p>x 1</p>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <p>Refund</p>
//                         <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div className="d-flex justify-content-between">
//                         <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
//                         <div>
//                           <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
//                           <p style={{ margin: "0 0 0 10px" }}>s</p>
//                         </div>
//                       </div>
//                       <div style={{ margin: "0 35px 0 0px" }}>
//                         <p>x 1</p>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <p>Refund</p>
//                         <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0 137px 0px" }}>
//                       <div className="d-flex justify-content-between">
//                         <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
//                         <div>
//                           <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
//                           <p style={{ margin: "0 0 0 10px" }}>s</p>
//                         </div>
//                       </div>
//                       <div style={{ margin: "0 35px 0 0px" }}>
//                         <p>x 1</p>
//                       </div>
//                     </div>

//                   </div>
//                 </div>
//               </div>

//               <div
//                 // className="card"
//                 style={{ margin: "30px,0,0 0 ", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>
//                 <div>
//                   <h4 className="mt-3 ms-5" style={{ margin: "0px 0 50px 0px" }}>
//                     Summary
//                   </h4>
//                   <div >
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <img src={data.image} alt="product image" width="35px" height="55px" style={{ borderRadius: "12px" }}></img>
//                           })
//                         }
//                         {/* <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height= "55px" style={{borderRadius:"12px"}}/> */}
//                         <span style={{ margin: "0 0 0 10px" }}> {
//                           listOrder?.items?.map((data: any) => {
//                             return data.name
//                           })
//                         }</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <span style={{ margin: "0 10px 0 0" }}>${data.price}</span>
//                           })
//                         }
//                         {/* <span style={{margin:"0 10px 0 0"}}>$30.00</span> */}
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <span style={{ margin: "0 10px 0 0" }}>x {data.quantity}</span>
//                           })
//                         }
//                         {/* <span style={{margin:"0 10px 0 0"}}>x 3</span> */}
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <span style={{ margin: "0 10px 0 0" }}>${data.itemTotal}</span>
//                           })
//                         }
//                         {/* <span style={{margin:"0 10px 0 0"}}>$90.00</span> */}
//                         {/* <span>usd</span> */}
//                       </div>
//                     </div>
//                     {/* <div className="d-flex justify-content-between mt-3 ms-5">
//                        <div>
//                          <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height= "55px" style={{borderRadius:"12px"}}/>
//                          <span style={{margin:"0 0 0 10px"}}>Medusa Water Bottle</span>
//                        </div>
//                        <div style={{margin:"0 45px 0 0px"}}>
//                          <span style={{margin:"0 10px 0 0"}}>$30.00</span>
//                          <span style={{margin:"0 10px 0 0"}}>x 3</span>
//                          <span style={{margin:"0 10px 0 0"}}>$90.00</span>
//                          <span>usd</span>
//                        </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                        <div>
//                          <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height= "55px" style={{borderRadius:"12px"}}/>
//                          <span style={{margin:"0 0 0 10px"}}>Medusa Water Bottle</span>
//                        </div>
//                        <div style={{margin:"0 45px 0 0px"}}>
//                          <span style={{margin:"0 10px 0 0"}}>$30.00</span>
//                          <span style={{margin:"0 10px 0 0"}}>x 3</span>
//                          <span style={{margin:"0 10px 0 0"}}>$90.00</span>
//                          <span>usd</span>
//                        </div>
//                     </div> */}
//                     <div
//                     //  className="d-flex justify-content-between mt-3 ms-5"
//                     >
//                       <div>
//                         <span>Subtotal</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <span style={{ margin: "0 10px 0 0" }}>${data.itemTotal}.00</span>
//                           })
//                         }
//                         {/* <span style={{margin:"0 10px 0 0"}}>$150.00</span> */}
//                         <span>USD</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Shipping</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>$0.00</span>
//                         <span>USD</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Tax</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>$0.00</span>
//                         <span>USD</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Original Total</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <h3>${data.itemTotal}.00</h3>
//                           })
//                         }
//                         {/* <h3>$160.00</h3> */}
//                         {/* <span>USD</span> */}
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5">
//                       <div>
//                         <span>Refunded for Returns</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         <span style={{ margin: "0 10px 0 0" }}>$30.00</span>
//                         <span>USD</span>
//                       </div>
//                     </div>
//                     <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0px 40px 0px" }}>
//                       <div>
//                         <span>Net Total</span>
//                       </div>
//                       <div style={{ margin: "0 45px 0 0px" }}>
//                         {
//                           listOrder?.items?.map((data: any) => {
//                             return <h5>${data.itemTotal}.00</h5>
//                           })
//                         }
//                         {/* <h5>$130.00</h5> */}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* </div> */}

//               <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>

//               </div>
//             </div>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               <div className="col-md-6">
//                 <span style={{ fontSize: "15px", fontWeight: "700" }}>Order Name : </span>{listOrder?.name}
//                 <div>
//                   <span style={{ fontSize: "15px", fontWeight: "700" }}> Email:</span> {listOrder?.email}
//                 </div>
//                 <div>
//                   <span style={{ fontSize: "15px", fontWeight: "700" }}> Phone Number:</span> {listOrder?.phone_no}
//                 </div>
//                 <div>
//                   <span style={{ fontSize: "15px", fontWeight: "700" }}> Status: </span>{listOrder?.status}
//                 </div>
//                 <div>
//                   <span style={{ fontSize: "18px", fontWeight: "700", textDecoration: "underline" }}> Item List:</span>
//                   <div>
//                     <span style={{ fontSize: "15px", fontWeight: "700" }}> Product Name: </span>
//                     {
//                       listOrder?.items?.map((data: any) => {
//                         return data.name
//                       })
//                     }
//                   </div>
//                   <div>
//                     <span style={{ fontSize: "15px", fontWeight: "700" }}> Product Price: </span>
//                     {
//                       listOrder?.items?.map((data: any) => {
//                         return data.price
//                       })
//                     }
//                   </div>
//                   <div>
//                     <span style={{ fontSize: "15px", fontWeight: "700" }}> Product quantity: </span>
//                     {
//                       listOrder?.items?.map((data: any) => {
//                         return data.quantity
//                       })
//                     }
//                   </div>
//                   <div>
//                     <span style={{ fontSize: "15px", fontWeight: "700" }}> Product stock: </span>
//                     {
//                       listOrder?.items?.map((data: any) => {
//                         return data.stock
//                       })
//                     }
//                   </div>
//                   <div>
//                     <span style={{ fontSize: "15px", fontWeight: "700" }}> Product Price Total: </span>
//                     {
//                       listOrder?.items?.map((data: any) => {
//                         return data.itemTotal
//                       })
//                     }
//                   </div>
//                   {/* <div>
//           <span style={{ fontSize: "15px", fontWeight: "700" }}> Product Image: </span>
//           {
//             listOrder?.items?.map((data: any) => {
//               return <img src={data.image} alt="product image" style={{ width: "100px" }}></img>
//             })
//           }
//         </div> */}
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div>
//                   <span style={{ fontSize: "15px", fontWeight: "700" }}> Product Image: </span>
//                   {
//                     listOrder?.items?.map((data: any) => {
//                       return <img src={data.image} alt="product image" style={{ width: "300px" }}></img>
//                     })
//                   }
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//     </div>
//   );
// };

// export default list;
