
import { IResourceComponentsProps, useExport, useGetIdentity, useNotification } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, Popconfirm, Modal, Form, Select, useSelect
} from "@pankod/refine-antd";
import { CatalogueMenu } from "components/setup/catalogue/menu";
import { CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { IPrice } from "interfaces";
import { CreateProductOptions } from "./create";
import { Delete_Payment_options, Delete_Product_Option } from "../../../query"
import { EditProductOptions } from "./edit";
import { useEffect, useState } from "react";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


export const ProductOptions: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();
    const [opened, setOpen] = useState(false);
    const userId = user?.metadata?.org_id;
    const [productOptionId, setProductOptionId] = useState(null)
    const { tableProps, sorter, tableQueryResult } = useTable<IPrice>({
        resource: "product_option",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
            {
                field: "id",
                operator: "eq",
                value: productOptionId,
            },
        ],
        metaData: {
            fields: ["id", "name", "created_at", "code", "type", "view_order"],
        },
    });
    const { formProps, modalProps, show } = useModalForm({
        resource: "product_option",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "product_option",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "created_at", "code", "type", "view_order", "list_value"],
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "product_option",
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
        filters:[
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          }
         ],
        onSearch: (value:any) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });

    const { triggerExport, isLoading } = useExport<IPrice>();
    let datacount = tableQueryResult?.data?.total;

    const handleDelete = (data: any) => {
         // Delete_Payment_options(data).then((repsone) => {
        //     if (repsone) {
        //         tableQueryResult?.refetch()
        //     }
        // })
        Delete_Product_Option(data).then((repsone:any) => {
            if (repsone) {
                open?.({
                    key: "success", type: "success", message: "", description: `Successfully Deleted`,
                  });
                  setTimeout(() => { close("success"); }, 4000);
                tableQueryResult?.refetch()
            }
        })
    }

    const handleSearch = (value:any) => {
        setProductOptionId(value)
       }
    
       const handleOnClearSearch = () => {
        setProductOptionId(null)
       }

    return (
        <List
            title="Product Options"
            pageHeaderProps={{

                extra: [
                    <>
                    {/*   <ExportButton onClick={triggerExport} loading={isLoading} />, */}
                    <Form
                layout="vertical"
                style={{ display: "flex", marginBottom: 17 }}
              >
                <Form.Item label="Name">
                  <Select
                    placeholder="Select a Product OPtion Name"
                    style={{ width: 300, marginRight: 20 }}
                    onSelect={handleSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...selectProps}
                  />
                </Form.Item>
              </Form>
                    <RefreshButton onClick={() => tableQueryResult?.refetch()}
                    type="primary"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                    />,
                    <CreateButton type="primary" resourceName="catalogue/productoptions"
                     children={<div style={{ display: "flex", alignItems: "center" }}>
                            <PlusOutlined style={{ marginRight: "5px" }} />
                            Add Product Options
                        </div>}
                        icon={false}
                      onClick={() => show()} />,
                      </>
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" sorter />
                <Table.Column dataIndex="code" title="Code" sorter />
                <Table.Column dataIndex="type" title="Type" sorter />
                <Table.Column dataIndex="view_order" title="View Order" sorter />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />

                <Table.Column<IPrice>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                hideText={true}
                                resourceName="catalogue/productoptions"
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                            />
                            {/* <DeleteButton
                                size="small"
                                type="link"
                                resourceName="catalogue/productoptions"
                                hideText={true}
                                recordItemId={record.id}
                            /> */}
                            <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                                <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <CreateProductOptions modalProps={modalProps} formProps={formProps} />
            <EditProductOptions modalProps={editModalProps} formProps={editFormProps} />
            <Modal visible={opened}
                maskStyle={{ width: "0px" }}
                bodyStyle={{ height: "80px" }}
                footer={null}
                width="27%"
                style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
            >
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

                    <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
                    <span style={{ paddingLeft: "3%", }}>
                        Successfully ProductOptions Deleted
                    </span>
                </div>
            </Modal>
        </List>
    );
};
