
import { IResourceComponentsProps,useExport, useGetIdentity } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    Popconfirm,
    getDefaultSortOrder,
    useModalForm,Icons, Modal, Form, Select, useSelect
} from "@pankod/refine-antd";
import { CatalogueMenu } from "components/setup/catalogue/menu";
import { ICoupons } from "interfaces";
import { CreateCouponsProduct } from "./create";
import { EditCouponsProduct } from "./edit";
import { Delete_Coupons } from "../../../query";
import React, { useState } from 'react';
import { CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';

const { PlusOutlined,MailOutlined, AppstoreOutlined, SettingOutlined, CloseOutlined, CheckOutlined} = Icons;


export const Coupons: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [open, setOpen] = useState(false);
    const [coupenId, setCoupenId] = useState(null)

    const { tableProps, sorter, tableQueryResult } = useTable<ICoupons>({
        resource: "coupons",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
            {
                field: "id",
                operator: "eq",
                value: coupenId,
            },
        ],
        metaData: {
            fields: ["id", "code", "created_at", "expires", "coupon_type", "percentage", "minimum_amount_of_order", "one_time_use", "active"],
        },
    });
    const { formProps, modalProps, show } = useModalForm({
        resource: "coupons",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
        
    } = useModalForm({
        resource: "coupons",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "code", "expires", "coupon_type", "percentage", "minimum_amount_of_order", "one_time_use", "active", "amount"],
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "coupons",
        metaData: {
          fields: ["id", "code"],
        },
        optionLabel: "code",
        optionValue: "id",
        filters:[
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          }
         ],
        onSearch: (value:any) => [
          {
            field: "code",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });

    const { triggerExport, isLoading } = useExport<ICoupons>();
    let datacount = tableQueryResult?.data?.total;

    const handleDelete = (data: any) => {
        Delete_Coupons(data).then((repsone) => {
            if (repsone) {
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 4000);
                tableQueryResult?.refetch()
            }
        })
    }

    const handleSearch = (value:any) => {
        setCoupenId(value)
       }
    
       const handleOnClearSearch = () => {
        setCoupenId(null)
       }

    return (
        <List 
        title="Coupons"
            pageHeaderProps={{

                extra: [
                    <>
                  {/* //  <ExportButton onClick={triggerExport} loading={isLoading} />, */}
                  <Form
                layout="vertical"
                style={{ display: "flex", marginBottom: 17 }}
              >
                <Form.Item label="Code">
                  <Select
                    placeholder="Select a Coupen Code"
                    style={{ width: 300, marginRight: 20 }}
                    onSelect={handleSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...selectProps}
                  />
                </Form.Item>
              </Form>
                    <RefreshButton
                    type="primary"
                    children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                    onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" resourceName="catalogue/coupon"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <PlusOutlined style={{ marginRight: "5px" }} />
                            Add Coupons
                        </div>}
                        icon={false}
                        onClick={() => show()} />,
                        </>
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="code" title="Code" sorter/>
                <Table.Column dataIndex="coupon_type" title="Coupon Type" sorter/>
                <Table.Column dataIndex="percentage" title="Percentage" sorter/>
                <Table.Column dataIndex="expires" title="Expires" sorter/>
                <Table.Column dataIndex="minimum_amount_of_order" title="Minimum amount of order" width="0" sorter/>
                <Table.Column dataIndex="one_time_use" title="One time use" key="one_time_use"
                render={(value) => 
                    (value == true ? 
                    <CheckOutlined style={{ color: '#6ead65' }} /> : 
                    <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                <Table.Column dataIndex="active" title="Active" key="active" render={(value) => 
                    (value == true ? 
                    <CheckOutlined style={{ color: '#6ead65' }} /> : 
                    <CloseOutlined style={{ color: '#ff7875' }} />)}/>             
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                
                <Table.Column<ICoupons>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                hideText = {true}
                                resourceName="catalogue/coupon"
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                            />
                            {/* <DeleteButton
                                size="small"
                                type="link"
                                resourceName="catalogue/coupon"
                                hideText = {true}
                                recordItemId={record.id}
                            /> */}
                            <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                                <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <CreateCouponsProduct modalProps={modalProps} formProps={formProps} />
            <EditCouponsProduct modalProps={editModalProps} formProps={editFormProps} />
            <Modal visible={open}
                maskStyle={{ width: "0px" }}
                bodyStyle={{ height: "80px" }}
                footer={null}
                width="27%"
                style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
            >
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

                    <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
                    <span style={{ paddingLeft: "3%", }}>
                    Coupon has been deleted
                    </span>
                </div>
            </Modal>
        </List>
    );
};
