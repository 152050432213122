import {
    IResourceComponentsProps,
    useExport,
    useGetIdentity,
    CrudFilters,
    HttpError,
} from "@pankod/refine-core";
import {
    Form,
    Input,
    RefreshButton,
    CreateButton,
    Divider,
    ModalProps,
    FormProps,
    Tabs,
    Modal,
    Button,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    EditButton,
    ExportButton,
    useTable,
    Typography,
    InputNumber,
    Select,
    useSelect,
    Dropdown,
    Space,
    Tooltip,
    Menu,
    Checkbox,
    Popconfirm,
    Table,
    Alert,
    Card,
    useModalForm,
} from "@pankod/refine-antd";
import { Editor } from "@tinymce/tinymce-react";
import { Col, Row, DatePicker } from "antd";
import { List } from "@pankod/refine-antd";
import {
    PictureOutlined,
    DownOutlined,
    UserOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    UploadOutlined,
    CheckCircleOutlined,
    ExclamationOutlined
} from "@ant-design/icons";
import type { UploadProps, MenuProps, FormInstance, InputRef } from "antd";
import { message, Upload } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
    IProduct,
    IOrg,
    ICategory,
    IbrandProduct,
    IPrice,
    IUser,
    IPostUserFilterVariables,
} from "interfaces";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLogin, useTranslate, useNotification } from "@pankod/refine-core";
import { gql } from "graphql-request";
import { nhost } from "utility";
import { jsonConverter } from "../../components/helpers/jsonConverter";
import { ICustomer, ICustomerFilterVariables } from "interfaces";
import { DELETE_USER, GET_USER, GRT_ALL_USER_DATA, USER_DATA } from "query";
import { setTimeout } from "timers";
import { UserEdit } from "./userEdit";


type EditCustomerProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    closeEditCreateModal: () => void;
    createEditModalVisible: boolean;
    handleEditFormSubmitted: () => void;
    tableQueryResult: any;
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const onChange = (key: string) => {
};

export const EditUser: React.FC<EditCustomerProps> = ({
    modalProps,
    formProps,
    tableQueryResult,
    handleEditFormSubmitted,
    closeEditCreateModal,
    createEditModalVisible,
}) => {
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();

    const [checkAddress, setCheckAddress] = useState(true);
    const [checkboxEmail, setCheckboxEmail] = useState(false);
    const [checkboxActive, setCheckboxActive] = useState(false);
    const [checkboxDisableOrder, setCheckboxDisableOrder] = useState(false);
    const [checkboxOrderXls, setCheckboxOrderXls] = useState(false);
    const [checkboxOrderPdf, setCheckboxOrderPdf] = useState(false);
    const [checkboxReceiveEmail, setCheckboxReceiveEmail] = useState(false);
    const [opened, setOpen] = useState(false);
    const [openedEditModal, setOpenEditModal] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [customerId, setCustomerId]: any = useState("");
    const [userData, setUserData]: any = useState();
    const [editUserData, setEditUserData]: any = useState();
    const allUserData: any = { userData };

    const t = useTranslate();
    const [openEdit, setOpenEdit] = useState(false);
    var data: any
    useEffect(() => {
        let tempcustomer_org_id = localStorage.getItem('customer_org_id');
        let customer_org_id = tempcustomer_org_id?.toString();
        setCustomerId(customer_org_id)
        data = { org_id: customer_org_id };
    }, [localStorage.getItem("customer_org_id")])

    const { tableProps, sorter, searchFormProps } = useTable<
        IUser,
        HttpError,
        IPostUserFilterVariables
    >({
        resource: "customers",
        // initialSorter: [
        //     {
        //         field: "created_at",
        //         order: "desc",
        //     },
        // ],
        // permanentFilter: [
        //     {
        //         field: "metadata",
        //         operator: "eq",
        //         value: data,
        //     },
        // ],
        metaData: {
            fields: ["id", "email"],
        },
        // onSearch: (params) => {
        //     const filters: CrudFilters = [];
        //     const { name } = params;
        //     filters.push(
        //         {
        //             field: "name",
        //             operator: "contains",
        //             value: name !== '' ? name : null,
        //         }
        //     );
        //     return filters;
        // },
    });
    const {
        formProps: editUserFormProps,
        modalProps: editUserModalProps,
        show: editUserShow,
    } = useModalForm({
        resource: "users",
        action: "edit",
        redirect: false,
        metaData: {
            fields: [
                "id",
                "disabled",
                "displayName",
                "email",
                "metadata",
            ],
        },
    });
    const handleEditCloseModal = () => {
        setEditModal(false);
        setEditUserData({});
    }
    const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
        resource: "ob_orgs",
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc" }],
    });

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc" }],
    });

    const { selectProps: priceListSelectProps } = useSelect<IPrice>({
        resource: "pricelists",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc" }],
    });

    const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
        resource: "brands",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc" }],
    });

    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        setCheckAddress(e.target.checked);
    };
    const onChangeCheckboxEmail = (e: CheckboxChangeEvent) => {
        setCheckboxEmail(e.target.checked);
    };
    const onChangeCheckboxActive = (e: CheckboxChangeEvent) => {
        setCheckboxActive(e.target.checked);
    };
    const onChangeCheckboxDisableOrder = (e: CheckboxChangeEvent) => {
        setCheckboxDisableOrder(e.target.checked);
    };
    const onChangeCheckboxOrderXls = (e: CheckboxChangeEvent) => {
        setCheckboxOrderXls(e.target.checked);
    };
    const onChangeCheckboxOrderPdf = (e: CheckboxChangeEvent) => {
        setCheckboxOrderPdf(e.target.checked);
    };
    const onChangeCheckboxReceiveEmail = (e: CheckboxChangeEvent) => {
        setCheckboxReceiveEmail(e.target.checked);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleEditModalCancel = () => {
        setOpenEditModal(false);
    };

    const getAllUsers = () => {
        GRT_ALL_USER_DATA().then((res:any) => {
            const getUser = res?.users?.filter((user:any) => {
                return user?.metadata?.org_id == customerId
            })
            setUserData(getUser)
        })
    }

    useEffect(() => {
        if (customerId) {
            // USER_DATA({ org_id: customerId }).then((response: any) => {                
            //     setUserData(response?.users)
            // });
            getAllUsers();
        }
    }, [customerId, editModal, opened]);
 

    const apidata = (e: any) => {
        // let metadata = { org_id: user?.metadata?.org_id };
        let metadata = { org_id: localStorage.getItem('customer_org_id') };
        const createUser = async () => {
            const result = await nhost.auth.signUp({
                email: e.user_email,
                password: e.password,
                options: {
                    defaultRole: "user",
                    displayName: `${e.first_name}`.trim(),
                    locale: "en",
                    metadata: {
                        org_id: metadata?.org_id,
                        last_name: e.last_name,
                        company_name: e.company_name
                    },
                },
            }).then((res: any) => {
                if(res?.error !== null){
                    open?.({ key: "email_error", type: "error", message: "", description: `${res?.error?.message}`,});
                    setTimeout(() => { close("email_error"); }, 3000);
                   }
                else if(res){
                    GET_USER(e.user_email).then((user: any) => {                    
                        if (user?.users) {
                            var user_id = user?.users[0]?.id

                            let UPDATEQUERY = gql`mutation MyMutation {
                            updateUsers(where: {id: {_eq: "${user_id}"}}, _set: {emailVerified:${true}}) {
                                returning{
                                    metadata
                                }
                            }
                        }
                        `;

                            nhost.graphql.request(UPDATEQUERY).then((updateUser: any) => {
                                if (updateUser?.error == null) {
                                    open?.({
                                        key: "success",
                                        type: "success",
                                        message: "",
                                        description: `Successfully User Created`,
                                    });

                                    setOpen(false);
                                    tableQueryResult?.refetch()
                                    setTimeout(() => {
                                        close("success");
                                    }, 3000);
                                }
                                else {
                                    open?.({ key: "error", type: "error", message: "", description: `${updateUser?.error?.message}`,});
                                    setTimeout(() => { close("error"); }, 3000);
                                }
                            })
                        }
                        else {
                            open?.({
                                key: "error",
                                type: "error",
                                message: "",
                                description: `Something Went Wrong ${res?.error?.message}`,
                            });
                            setTimeout(() => {
                                close("error");
                            }, 3000);
                        }
                    })
                }
            })
        }
          createUser()

          let QUERY = gql`mutation MyMutation {
            insertUsers(objects: {email: "${e.username}", displayName:"${e.fullname}", avatarUrl: "${e.username}",locale:"en",emailVerified:${false},  disabled: ${false},phoneNumberVerified:${false},defaultRole:"user",passwordHash: "${e.password}", metadata:${jsonConverter(metadata)}}) {
                affected_rows
                returning{
                    id
                    metadata
                }
                }
            }`;
        const onFinish = () => {
            nhost.graphql.request(QUERY).then((response: any) => {
                // localStorage.removeItem('customer_org_id')
                let id = response?.data?.insertUsers?.returning[0]?.id;
                if (!id) {
                    setOpenEmail(true)
                    setOpen(true)
                    setTimeout(() => {
                        setOpenEmail(false);
                    }, 4000);
                }
                else {
                    setOpenEdit(true)
                    setOpen(false)
                }
                let UPDATEQUERY = gql`mutation MyMutation {
                        updateUsers(where: {id: {_eq: "${id}"}}, _set: {metadata: ${jsonConverter(metadata)}}) {
                            returning{
                                metadata
                            }
                        }
                      }
                      `;
                if (response) {
                    nhost.graphql.request(UPDATEQUERY).then((response: any) => {
                    });
                }
            });
        };
        setTimeout(() => {
            setOpenEdit(false);
        }, 3000);
        // userData?.refetch()
        closeEditCreateModal();
        tableQueryResult.refetch()
        // onFinish();
    };

    const handleEditCancel = () => {
        setOpenEdit(false);
    };
    const handleEmailCancel = () => {
        setOpenEmail(false);
    };

    const handleDelete = (data: any) => {        
        DELETE_USER(data).then((repsone:any) => {
          if (repsone) {
            open({ key: "success", type: "success", message: "", description: `Successfully User Deleted`,})
            getAllUsers();
            setTimeout(() => {
                close("success");
            }, 3000);
            tableQueryResult?.refetch()
          }
        })
      }

      const validateCompanyName = (_:any, value:any) => {
        if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
          return Promise.reject('Please enter valid email address');
        }
        return Promise.resolve();
      };

    return (
        <>
            {/* Create Modal Popup Start */}
            <Modal
                {...modalProps}
                title="User"
                onCancel={handleCancel}
                visible={opened}
            >
                    <Form
                        {...formProps}
                        onFinish={(e) => {
                            apidata(e);
                        }}
                        wrapperCol={{ span: 23 }}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Col xs={22}>
                            <Form.Item
                                name="first_name"
                                label="First Name"
                                // label={t("pages.login.first_name", "First Name")}
                                rules={[{ required: true, message: 'Please input your First Name!' }]}
                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="last_name"
                                label="Last Name"
                                rules={[{ required: true, message: 'Please input your Last Name!' }]}

                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="user_email"
                                label="Work Email"
                                // label={t("pages.login.user_email", "Work Email")}
                                rules={[{ required: true },{ validator: validateCompanyName },]}
                                // rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                <Input size="large" placeholder="" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
                            </Form.Item>

                            <Form.Item
                                name="company_name"
                                label="Company Name"
                                // label={t("pages.login.company_name", "Company Name")}
                                rules={[{ required: true, message: 'Please input your Company Name!' }]}
                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                // label={t("pages.login.password", "Password")}
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                                style={{ marginBottom: "12px" }}
                            >
                                <Input type="password" placeholder="" size="large" />
                            </Form.Item>
                        </Col>
                        <Form.Item name="updated_by" initialValue={user?.id}>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            name="created_by"
                            initialValue={localStorage.getItem("id")}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                    </Form>
            </Modal>
            {/* Create Modal Popup End */}
            {/* Edit Modal Popup Start */}
            <Modal
                {...modalProps}
                title="Edit User"
                onCancel={handleEditModalCancel}
                visible={openedEditModal}
            >
                    <Form
                        {...userData}
                        onFinish={(e) => {
                            apidata(e);
                        }}
                        initialValues={{
                            fullname: formProps?.initialValues?.fullname,
                            lastname: formProps?.initialValues?.name,
                            username: formProps?.initialValues?.email,
                            password: formProps?.initialValues?.password,
                        }}
                        wrapperCol={{ span: 23 }}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Col xs={22}>
                            <Form.Item
                                name="fullname"
                                label={t("pages.login.firstname", "First Name")}
                                rules={[{ required: true }]}
                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                label={t("pages.login.lastname", "Last Name")}
                                rules={[{ required: true }]}
                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="user_email"
                                label={t("pages.login.email", "Work Email")}
                                rules={[{ required: true, message: "email Exists" }]}

                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>

                            <Form.Item
                                name="companyname"
                                label={t("pages.login.company", "Company Name")}
                                rules={[{ required: true }]}
                            >
                                <Input size="large" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label={t("pages.login.password", "Password")}
                                rules={[{ required: true }]}
                                style={{ marginBottom: "12px" }}
                            >
                                <Input type="password" placeholder="" size="large" />
                            </Form.Item>
                        </Col>
                        <Form.Item name="updated_by" initialValue={user?.id}>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            name="created_by"
                            initialValue={localStorage.getItem("id")}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                    </Form>
            </Modal>
            {/* Edit Modal Popup End */}
            <Modal
                {...modalProps}
                title="User"
                onCancel={closeEditCreateModal}
                visible={createEditModalVisible}
            >
                <List
                    title=""
                    pageHeaderProps={{
                        extra: [
                            //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                            // <RefreshButton
                            //     // style={{ marginTop: '15px' }}
                            //     onClick={() => tableQueryResult?.refetch()}
                            // />,
                            <CreateButton
                                type="primary"
                                //  style={{ marginTop: '15px' }}
                                resourceName="customers/customer"
                                children={<div style={{display: "flex", alignItems: "center"}}>
                                    <PlusOutlined style={{marginRight: "5px"}}/>
                                    Add User
                                </div>}
                                icon={false}
                                // children="Add User"
                                // icon={<PlusOutlined />}
                                onClick={() =>
                                    {
                                    // show()
                                    closeEditCreateModal();
                                    setOpen(true);

                                }
                                }
                            />,
                        ],
                    }}
                >
                    {/* <span>Records: {datacount}</span> */}
                    <Table rowKey="id" size="small" dataSource={userData}  >
                        <Table.Column dataIndex="displayName" title="Name"
                        />
                        <Table.Column dataIndex="email" title="Email" />
                        <Table.Column dataIndex="phoneNumber" title="Contact" />
                        <Table.Column<IUser>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    {/* <Link to="viewOrder"><Button onClick={handleViewButton}>View</Button></Link> */}

                                    <EditButton
                                        size="small"
                                        type="link"
                                        icon={null}
                                        recordItemId={record.id}
                                        resourceName="customers/customer"
                                        onClick={() =>{ 
                                            setEditModal(true);
                                            setEditUserData({initialValues: record})
                                            closeEditCreateModal();
                                        }}
                                    />

                                    {/* <DeleteButton
                                        size="small"
                                        type="link"
                                        icon={null}
                                        resourceName="customers/customer"
                                        recordItemId={record.id}
                                    /> */}
                                    <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                                        <a style={{ color: "red" }}>Delete</a>
                                    </Popconfirm>
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Modal>
                    <UserEdit formProps={editUserData} modalProps={editUserModalProps} closeEditModal={handleEditCloseModal} createEditModal={editModal} />
            <Modal
                visible={openEdit}
                maskStyle={{ width: "0px" }}
                bodyStyle={{ height: "80px" }}
                onCancel={handleEditCancel}
                footer={null}
                width="27%"
                style={{
                    position: "absolute",
                    marginTop: "-5%",
                    marginLeft: "70%",
                    gridRow: "auto",
                }}
            >
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
                    <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
                    <span style={{ paddingLeft: "3%" }}>
                        Invited To User Successfully
                    </span>
                </div>
            </Modal>
            <Modal
                visible={openEmail}
                maskStyle={{ width: "0px" }}
                bodyStyle={{ height: "80px" }}
                onCancel={handleEmailCancel}
                footer={null}
                width="27%"
                style={{
                    position: "absolute",
                    marginTop: "-5%",
                    marginLeft: "70%",
                    gridRow: "auto",
                }}
            >
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
                    <ExclamationOutlined style={{ color: "red", fontSize: "22px" }} />
                    <span style={{ paddingLeft: "3%" }}>
                        User mail is already exists
                    </span>
                </div>

            </Modal>
        </>
    );
}