import { Form, FormProps, Input, Modal, ModalProps } from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import { nhost } from "utility";


type EditAlertProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    closeModal: () => void
    tableQueryResult: any,
  };

const { TextArea } = Input;

export const EditCustomeAlert: React.FC<EditAlertProps> = ({modalProps, formProps, closeModal, tableQueryResult}) =>{
    const { open, close } = useNotification();
    
    const updateCustomeAlert =async (value: {custom_alert: string}) =>{
        const mutation = `mutation MyMutation {
        update_customers(where: {id: {_eq: "${formProps?.initialValues?.id}"}}, _set: {custom_alert: "${value?.custom_alert}"}) {
            affected_rows
            }
        }
        ` 
        await nhost.graphql.request(mutation).then((res: any)=>{
            if(res?.data){
                open?.({
                    key:"success",
                    type: "success",
                    message: "",
                    description: `Successfully updated custom alert message`,
                });
                tableQueryResult.refetch()
                closeModal()
                setTimeout(() => {
                    close("success");
                }, 4000);
            }
        }) 
    }

    return(
        <Modal {...modalProps} title ="Custom Alert">
            <Form {...formProps} layout="vertical" onFinish={(value: any)=>{updateCustomeAlert(value)
            }}>
                <Form.Item label={"Alert Message"} name ="custom_alert" >
                    <TextArea rows={4} style={{ width: "100%" }} />
                </Form.Item>
            </Form>
        </Modal>
    )
}