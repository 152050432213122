import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  CreateButton,
  Form,
  List,
  Select,
  ShowButton,
  Space,
  Table,
  useModalForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { CreateTBC } from "./show";
import { useState } from "react";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { ICustomer, ICustomerFilterVariables } from "interfaces";

export const ListTBC: React.FC<IResourceComponentsProps> = () => {
  const [customerId, setCustomerId] = useState<any>();
  const [customerdata, setCustomerData] = useState<any>();
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  const { formProps, modalProps, show, close } = useModalForm({
    // resource: "orders",
    action: "create",
  });

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    ICustomer,
    HttpError,
    ICustomerFilterVariables
  >({
    resource: "customers",
    initialPageSize: 10,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: customerId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "fullname",
        "address",
        "status",
        "created_at",
        "customer_org_id",
        "ob_org_id"
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name !== "" ? name : null,
        },
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId,
        }
      );
      return filters;
    },
  });

  const { selectProps } = useSelect<any>({
    resource: "customers",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const handleSearch = (e: any) => {
    setCustomerId(e);
  };

  const handleOnClearSearch = () => {
    setCustomerId(null);
  };

  return (
    <List
      title="TBC"
      pageHeaderProps={{
        extra: [
          <>
            <Form layout="vertical">
              <Form.Item label ="Customer">
                <Select
                  placeholder="Select a customer"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
            </Form>
          </>,
        ],
      }}
    >
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column dataIndex={"name"} title="Name" />
        <Table.Column<ICustomer>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button
                type="primary"
                style={{ border: "none" }}
                onClick={() => {
                  setCustomerData(record);
                  show();
                }}
              >
                Show
              </Button>
            </Space>
          )}
        />
      </Table>
      <CreateTBC
        modalProps={modalProps}
        formProps={formProps}
        closeModal={close}
        record={customerdata}
      />
    </List>
  );
};
