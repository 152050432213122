import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  useForm,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table, Switch
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport, useList, useNotification } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { jsonConverter } from "components/helpers/jsonConverter";
import { nhost } from "utility";
import "./pages.css"

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface BasicInfoProps {
  basicinfo?: {} | any
}

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

export const BasicInfo: React.FC<BasicInfoProps> = ({ basicinfo }) => {
  const { open, close } = useNotification();

  const [publicProduct, setPublicProduct] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [publicPages, setPublicPages] = useState(false);
  const [form] = Form.useForm();
  const [opened, setOpen] = useState(false)
  const [intialFormData, setFormData] = useState<any>([])
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [urlList, setUrlList] = useState([] as any[]);
  const [faviconUrl, setFaviconUrl] = useState([] as any[]);
  const [stockSwitch, setStockSwitch] = useState<boolean>(false)
 
  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChange = (key: string) => {


  };

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList((urlList:any) => [ data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  const uploadImagesForFavicon = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then((data:any) => {
            setFaviconUrl((urlList:any) => [ data.secure_url]); 
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };
  const removeImage = () => {
    setFaviconUrl([])
  }
  const removeLogoImage = () => {
    setUrlList([])
  }
  const onChangePublicProduct = (e: CheckboxChangeEvent) => {
    setPublicProduct(e.target.checked);
  };
  const onchangeShowPrice = (e: CheckboxChangeEvent) => {
    setShowPrice(e.target.checked);
  };
  const onChangeCheckboxPublicPages = (e: CheckboxChangeEvent) => {
    setPublicPages(e.target.checked);
  };

  useEffect(() => {

  }, [publicProduct, publicPages, showPrice])

  useEffect(() => {
    setFormData(basicinfo?.data?.data[0])
    form.setFieldsValue(basicinfo?.data?.data[0])
    setUrlList([basicinfo?.data?.data[0]?.logo])
    setFaviconUrl([basicinfo?.data?.data[0]?.favicon])    
    setStockSwitch(basicinfo?.data?.data[0]?.allow_out_of_stock)
  }, [basicinfo?.data])


  const handleStockSwitch = (e: any) =>{
    setStockSwitch(e)
  }

  const apidata = (e: any) => {
    let showPrice = e.show_price ?? false
    let public_pages = e.public_pages ?? false
    let public_product = e.public_product ?? false
    const MUTATIONAPI = `mutation MyMutation {
      update_ob_orgs(_set: {name: "${e.name.trim()}",show_price: ${showPrice}, email: "${e.email}", language: "${e.language}", 
      company_description: "${e.company_description}", minimum_order: ${e.minimum_order}, country: "${e.country}", timezone: "${e.timezone}",
      currency: "${e.currency}", email_signature: "${e.email_signature}", public_product: ${public_product},allow_out_of_stock: "${e.allow_out_of_stock}"
      public_pages: ${public_pages}, out_of_product_status: "${e.out_of_product_status}", logo: ${e.logo ? `"${e.logo}"` : null},
      created_by: "${user?.id}", favicon: ${e.favicon_url ? `"${e.favicon_url}"` : null}}, where: { id: { _eq: "${userId}" } }) {
        affected_rows
      }
    }
    `
    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      open?.({ key: "success", type: "success", message: "", description: `Successfully Updated Basic Info`,});
      setTimeout(() => { close("success"); }, 3000);
      return data
    }
    onSubmit();
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const validateCompanyName = (_:any, value:any) => {
    if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
      return Promise.reject('Please enter valid email address');
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={form}
        // initialValues={basicinfo?.data?.data[0]}
        wrapperCol={{ span: 23 }}
        onFinish={(e) => {
          e.logo = urlList.join(",")
          e.favicon_url = faviconUrl.join(",")
          apidata(e)
        }}
        autoComplete="off"
        layout="vertical"
        className="profile_page"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input your company name!' }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true },{ validator: validateCompanyName },]}
              // rules={[{ required: true, message: 'Please input your email!' }]}
              >

              <Input onChange={(e) => onChange} style={{ width: "100%" }} type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
            </Form.Item>
            <Form.Item
              label="Language"
              name="language"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="english" name="language" >English</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="company_description"
              label="Company Description"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <TextArea rows={4} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="minimum_order"
              label="Minimum Order"
              rules={[{ required: true, message: 'Please input the minimum order!' }]}>

              <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="UK" name="country" >UK</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Timezone"
              name="timezone"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="UTC" name="Timezone" >(UTC) Coordinated Universal Time</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginTop: '10px' }}
              name="show_price" valuePropName="checked">
              <Checkbox
                name="show_price" checked={showPrice} onChange={onchangeShowPrice}
              >Show Price</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Currency"
              name="currency"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="GBP">(GBP) British Pound</Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
              label="Logo"
            >
              <div style={{ marginTop: '-15px' }}>
                <Dragger {...props} name="logo" customRequest={uploadImages}
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              <Form.Item
                style={{ width: '100%' }}
              >
                <h1 style={{ marginTop: '10px', fontSize: '14px' }}>Uploaded image will be displayed here</h1>
                {urlList.map((url) => {
                  return <img style={{ width: '10%', padding: '5px' }} src={url} />
                })}
              </Form.Item>
              </Form.Item> */}

              <Form.Item label="Logo" name="logo">
            <div className="Image_div">
            <div>
              <Dragger showUploadList={false}
               {...props} 
               name="logo" 
               customRequest={uploadImages}
              >
                <p className="ant-upload-drag-icon">
                  <PictureOutlined />
                </p>
                <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
              </Dragger>
            </div>
                  <div className="category_img_div">
                    <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                    {urlList?.map((url:any) => {
                      return <div>
                      <img className="category_img" src={url} />
                       {url ? <span ><DeleteOutlined onClick={removeLogoImage}/></span> : <></> }
                      </div>
                    })}
                    </div>
                    </div>
                  </Form.Item>
            <Form.Item label="Favicon" name="favicon_url">
            <div className="Image_div">
            <div>
              <Dragger showUploadList={false}
               {...props} 
               name="favicon_url" 
               customRequest={uploadImagesForFavicon}
              >
                <p className="ant-upload-drag-icon">
                  <PictureOutlined />
                </p>
                <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
              </Dragger>
            </div>
                  <div className="category_img_div">
                    <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                    {faviconUrl?.map((url:any) => {
                      return <div>
                      <img className="category_img" src={url} />
                       {url ? <span ><DeleteOutlined onClick={removeImage}/></span> : <></> }
                      </div>
                    })}
                    </div>
                    </div>
                  </Form.Item>
            <Form.Item
              name="email_signature"
              label="Email Signature"
              rules={[{ required: true, message: 'Please input your email signature!' }]}
            >
              <TextArea rows={4} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="public_product" valuePropName="checked">
              <Checkbox
                name="public_product" checked={publicProduct} onChange={onChangePublicProduct}
              >Public products</Checkbox>
            </Form.Item>
            <Form.Item name="public_pages" valuePropName="checked">
              <Checkbox
                name="public_pages" checked={publicPages} onChange={onChangeCheckboxPublicPages}
              >Public pages</Checkbox>
            </Form.Item>
            <Form.Item
              label = "Allow to Order Out of Stock Products"
              name = "allow_out_of_stock"
            >
              <Switch checked = {stockSwitch} onChange={handleStockSwitch}/>
            </Form.Item>
            <Form.Item
              label="Stock status"
              name="out_of_product_status"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="avaliable" name="out_of_stack" >Avaliable</Select.Option>
                <Select.Option value="limited_stack" name="out_of_stack" >Limited Stock</Select.Option>
                <Select.Option value="pre-orders" name="out_of_stack" >Pre-Orders</Select.Option>
                <Select.Option value="not_avaliable" name="out_of_stack" >Not Avaliable</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="created_by"
              initialValue={user?.id}
            >
              <Input type="hidden" />
            </Form.Item>
            <input type="submit" className="input-save" value="Save" />
          </Col>
        </Row>
      </Form>

      <Modal visible={opened}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Updated Basic Info</span>
        </div>
      </Modal>
    </>

  );
};
